// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  top: 0;
  display: block;
  width: 100%;
  height: auto;
  bottom: 220px;
  position: absolute;
}
ion-content .image-editor-container {
  width: 100%;
  height: 100%;
  position: relative;
}
ion-content .image-editor-container app-image-editor {
  width: 100%;
  height: 100%;
}

.commands {
  position: absolute;
  bottom: 0;
  height: 220px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.commands .edit-commands {
  display: block;
  padding: 20px;
}
.commands .edit-commands .command-button {
  display: flex;
  flex-direction: column;
}
.commands .edit-commands .command-button ion-icon {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}
.commands .edit-commands .command-button ion-icon.active {
  display: none;
  background-color: var(--mi-primary-color);
}
.commands .edit-commands .command-button ion-icon.filter {
  width: 36px;
  height: 36px;
  padding: 21px;
  border: solid 1px var(--mi-grey-color-11);
}
.commands .edit-commands .command-button ion-text {
  margin-top: 5px;
  text-align: center;
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-large-1);
  font-weight: var(--font-weight-semi-bold);
  font-style: normal;
}
.commands .dialog-commands {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.commands .dialog-commands ion-button {
  --padding-top: 10px;
  --padding-bottom: 10px;
  height: auto;
  min-width: 90px;
  text-transform: none;
  margin: 0 10px;
}
.commands .dialog-commands .btn-undo {
  --background: var(--mi-blue-color-4);
  --background-activated: var(--mi-blue-color-4);
  --background-focused: var(--mi-blue-color-4);
  --background-hover: var(--mi-blue-color-4);
  border: solid 1px var(--mi-blue-color-4);
  --color: var(--mi-grey-color-13);
}
.commands .dialog-commands .btn-undo.hide {
  visibility: hidden;
}
.commands.edit-crop .edit-commands .command-button .crop.default, .commands.edit-draw .edit-commands .command-button .draw.default, .commands.edit-shape .edit-commands .command-button .shape.default, .commands.edit-icon .edit-commands .command-button .icon.default, .commands.edit-text .edit-commands .command-button .text.default, .commands.edit-filter .edit-commands .command-button .filter.default {
  display: none;
}
.commands.edit-crop .edit-commands .command-button .crop.active, .commands.edit-draw .edit-commands .command-button .draw.active, .commands.edit-shape .edit-commands .command-button .shape.active, .commands.edit-icon .edit-commands .command-button .icon.active, .commands.edit-text .edit-commands .command-button .text.active, .commands.edit-filter .edit-commands .command-button .filter.active {
  display: block;
}

.btn-cancel {
  --color: var(--mi-grey-color-11);
  --border-color: var(--mi-grey-color-11);
}

.btn-save {
  --color: var(--mi-white-color);
  --border-color: var(--mi-primary-color);
  --backlground-activated: var(--mi-primary-color);
  --background-focused: var(--mi-primary-color);
  --background: var(--mi-primary-color);
}

swiper ::ng-deep .swiper-wrapper {
  justify-content: space-evenly;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
