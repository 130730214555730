import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ImageEditorDialogComponent } from 'src/app/modules/image-editor/edit-image-dialog/image-editor-dialog.component';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AttachmentMimeType, isImage, isVideo } from 'src/app/services/attachments.model';
import { ClinicalCaseService } from 'src/app/services/case-library/clinical-case.service';
import { FileSelectLimits, FileType, SelectedFile } from 'src/app/modules/file-select/file-select.model';
import { PersonalMediaGalleryService } from 'src/app/services/personal-media-gallery/personal-media-gallery.service';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { UploadService } from 'src/app/services/upload.service';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { SwiperLogic } from 'src/app/services/utils/swiper-logic';
import { isMobilePlatform, waitTime } from 'src/app/services/utils/utils';
import { ClinicalCase } from 'src/app/services/yeti-protocol/clinical-case';
import {
  UpdatePersonalMediaGalleryDocumentSuccessResponse,
  UploadPersonalMediaGalleryDocumentsSuccessResponse
} from 'src/app/services/yeti-protocol/personal-media-gallery';
import Swiper, { SwiperOptions } from 'swiper';
import {
  CreateEditCasePostBottomBarComponent,
  CreateEditCasePostBottomBarConfig
} from '../create-edit-case-post-bottom-bar/create-edit-case-post-bottom-bar.component';
import { FileSelectComponent } from '../../modules/file-select/components/file-select/file-select.component';
import { PersonalMediaGalleryDocumentWithStageOfTreatment, StageOfTreatmentId } from '../stage-of-treatment/stage-of-treatment.model';
import { Platform } from 'src/config/config.model';
import { SocketService } from 'src/app/services/socket.service';
import { DocumentUploadedNotification, UploadFile } from 'src/app/services/yeti-protocol/upload';
import appConfig from 'src/config/config';
import { TutorialData, TutorialForFeature, TutorialService } from 'src/app/services/tutorial.service';
import { FileSelectScope } from '../../modules/file-select/services/file-select.service';
import { ActionTracked, TrackingRequest, UploadMediaTrackingParam } from '../../services/yeti-protocol/tracking';
import { TRACKING_SERVICE, TrackingService } from '../../services/tracking/tracking.model';
import { ActionOnInit, VideoToPlay } from './case-image-upload-edit.model';
import {
  UIUtilsServiceInterface,
  UI_UTILS_SERVICE,
  GroupedImagesByStageOfTreatment
} from 'src/app/services/utils/ui-utils.service.interface';
import { IconLabelPosition } from 'src/app/modules/buttons/base-button/base-button.component';
import { ImageGalleryCaseStrategy } from '../../services/image-gallery/image-gallery-case-service.service';


interface CaseImageUploadEditComponentConfig {
  platform: Platform
}

const TUTORIAL_KEY = TutorialForFeature.CASE_FOLIO;

@Component({
  selector: 'app-case-image-upload-edit',
  templateUrl: './case-image-upload-edit.component.html',
  styleUrls: ['./case-image-upload-edit.component.scss'],
})
export class CaseImageUploadEditComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('fileSelectImage') fileSelectImage: FileSelectComponent;
  @ViewChild('fileSelectVideo') fileSelectVideo: FileSelectComponent;
  @ViewChild('createEditCasePostBottomBar') createEditCasePostBottomBar: CreateEditCasePostBottomBarComponent;

  @Input() clinicalCase: ClinicalCase;
  @Input() showErrorIfStageOfTreatmentIsEmpty: boolean;
  @Input() images: Array<PersonalMediaGalleryDocumentWithStageOfTreatment> = [];
  @Input() showBottomBar: boolean;
  @Input() watermarked: boolean;
  @Input() tutorialInfo: TutorialData;
  @Input() classificationLevelOne: string;
  @Input() triggerActionOnInit: ActionOnInit;
  @Input() stageOfTreatment: StageOfTreatmentId;
  @Input() areAllImagesTagedWithStageOfTreatment: boolean;

  @Output() imagesChanged: EventEmitter<Array<PersonalMediaGalleryDocumentWithStageOfTreatment>> = new EventEmitter();
  @Output() playVideo: EventEmitter<VideoToPlay> = new EventEmitter();
  @Output() tutorialBubbleStep3Showed: EventEmitter<void> = new EventEmitter();

  config: CaseImageUploadEditComponentConfig = appConfig;
  caseImagesUploading = 0;
  caseImagesProcessing = 0;
  swiperLogic: SwiperLogic;
  FileType = FileType;
  AttachmentMimeType = AttachmentMimeType;
  maxImagesCount = FileSelectLimits.maxImagesCount;
  maxVideosCount = FileSelectLimits.maxVideoFiles;
  maxImageFileSizeMb = FileSelectLimits.imageMaxFileSizeMB;
  maxVideoFileSizeMb = FileSelectLimits.maxVideoFileSizeMb;
  createEditCasePostBottomBarConfig: CreateEditCasePostBottomBarConfig;
  stageOfTreatmentElement: HTMLElement;
  imageActionButtonsElement: HTMLElement;
  tutorialKey = TUTORIAL_KEY;
  step4ArrowLeftPosition = '0px';
  swiper: Swiper;
  FileSelectScope = FileSelectScope;
  fullWatermarkedImageLoadError = false;
  fullSizeImageLoading = false;
  fullSizeWatermarkedImageLoading = false;
  IconLabelPosition = IconLabelPosition;

  private uploadingImagesMessageContainerClass = '.uploading-case-images-info-message-container';
  private processingImagesMessageContainerClass = '.processing-case-images-info-message-container';
  private resizeSubscription: Subscription;
  private documentUploadedSubscription: Subscription;

  constructor(
    private el: ElementRef,
    private responsiveUtilsService: ResponsiveUtilsService,
    private toast: ToastService,
    private personalMediaGalleryService: PersonalMediaGalleryService,
    private modalController: ModalController,
    private uploadService: UploadService,
    private clinicalCaseService: ClinicalCaseService,
    private appTranslationService: AppTranslationService,
    private socketService: SocketService,
    private zone: NgZone,
    public tutorialService: TutorialService,
    private imageGalleryCaseStrategy: ImageGalleryCaseStrategy,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface
  ) {
    this.swiperLogic = new SwiperLogic(345, this.responsiveUtilsService, this.el, 1, false, false);
  }

  ngOnInit(): void {
    this.createEditCasePostBottomBarConfig = {
      images: {
        avaliable: () => { return true },
      },
      videos: {
        avaliable: () => { return true },
      },
      reorder: {
        avaliable: () => { return true },
        disabled: () => { return !this.areAllImagesTagedWithStageOfTreatment },
        withBadge: () => { return true }
      },
      watermark: {
        avaliable: () => { return true },
        disabled: () => { return this.disableWatermarkButton },
        active: () => { return this.watermarked }
      },
      settings: {
        avaliable: (): boolean => { return this.shouldShowActionSheet }
      },
    }

    this.resizeSubscription = this.responsiveUtilsService.resize.subscribe(() => this.screenSizeChanged());
    this.documentUploadedSubscription = this.socketService.documentUploaded.subscribe((doc: DocumentUploadedNotification) => {

      this.zone.run(() => {

        this.caseImagesProcessing -= 1;

        if (!isVideo(doc.mimeType)) {
          return;
        }

        const videoImage = this.images.find(img => img.fileName === doc.fileName);

        if (videoImage) {
          videoImage.previewUrl = doc.previewUrl;
          videoImage.fullUrl = doc.fullUrl;
          videoImage._id = doc._id;
          videoImage.blurredUrl = doc.blurredUrl;
          videoImage.deleted = doc.deleted;
          videoImage.watermarkedPreviewUrl = doc.watermarkedPreviewUrl;
          videoImage.contentLength = doc.contentLength;
          videoImage.createdDate = doc.createdDate;
        }
      });
    })

    setTimeout(() => {
      if (this.triggerActionOnInit) {
        switch (this.triggerActionOnInit) {
          case ActionOnInit.ADD_IMAGES:
            this.addImages();
            break;
          case ActionOnInit.ADD_VIDEOS:
            this.addVideos();
            break;
        }
      }
    }, 250);
  }

  ngAfterViewInit(): void {
    this.addMessageToBodyElement(this.uploadingImagesMessageContainerClass);
    this.addMessageToBodyElement(this.processingImagesMessageContainerClass);

    waitTime(500).then(() => {
      this.swiperLogic.afterViewInit();
    });
  }

  ngOnDestroy(): void {
    this.swiperLogic.destroy();
    this.removeMessageFromBodyElement(this.uploadingImagesMessageContainerClass);
    this.removeMessageFromBodyElement(this.processingImagesMessageContainerClass);
    this.resizeSubscription?.unsubscribe();
    this.documentUploadedSubscription.unsubscribe();
  }

  get swiperConfig(): SwiperOptions {
    return this.swiperLogic.config;
  }

  async deleteImage(image: PersonalMediaGalleryDocumentWithStageOfTreatment): Promise<void> {

    if (!image?._id || image?._id.length === 0 || this.disableImageDeleteButton) {
      return;
    }

    const imageIndex = this.getImageIndexInImagesArray(image);

    if (imageIndex === -1) {
      return;
    }

    const shouldProceedWithDelete = await this.imageGalleryCaseStrategy.shouldProceedWithDelete();

    if (shouldProceedWithDelete) {
      if (this.clinicalCase) {
        this.deleteImageFromClinicalCase(image, imageIndex);
      } else {
        this.images.splice(imageIndex, 1);
        this.emitImagesChanged();
      }
    }
  }

  deleteImageFromClinicalCase(image: PersonalMediaGalleryDocumentWithStageOfTreatment, imageIndex: number): void {

    this.findAndRemoveImageInClinicalCaseImages(image, this.clinicalCase.preOpDocuments);
    this.findAndRemoveImageInClinicalCaseImages(image, this.clinicalCase.intraOpDocuments);
    this.findAndRemoveImageInClinicalCaseImages(image, this.clinicalCase.postOpDocuments);
    this.findAndRemoveImageInClinicalCaseImages(image, this.clinicalCase.clinicalFollowUpDocuments);

    const clinicalCaseData = {
      preOpDocuments: [],
      intraOpDocuments: [],
      postOpDocuments: [],
      clinicalFollowUpDocuments: []
    };

    clinicalCaseData.preOpDocuments = this.clinicalCase.preOpDocuments?.map(doc => doc._id);
    clinicalCaseData.intraOpDocuments = this.clinicalCase.intraOpDocuments?.map(doc => doc._id);
    clinicalCaseData.postOpDocuments = this.clinicalCase.postOpDocuments?.map(doc => doc._id);
    clinicalCaseData.clinicalFollowUpDocuments = this.clinicalCase.clinicalFollowUpDocuments?.map(doc => doc._id);

    this.clinicalCaseService.updateCase(this.clinicalCase._id, clinicalCaseData).then(() => {
      this.images.splice(imageIndex, 1);
      this.emitImagesChanged();
    }).catch(err => {
      this.showError(err?.message);
    });
  }

  findAndRemoveImageInClinicalCaseImages(
    image: PersonalMediaGalleryDocumentWithStageOfTreatment,
    documents: Array<PersonalMediaGalleryDocumentWithStageOfTreatment>): void {

    let clinicalCaseImageIndex = -1;

    clinicalCaseImageIndex = documents?.findIndex(img => img._id === image._id);

    if (clinicalCaseImageIndex >= 0) {
      documents.splice(clinicalCaseImageIndex, 1);
    }

  }

  editImage(image: PersonalMediaGalleryDocumentWithStageOfTreatment): Promise<void> {

    if (!image?._id || image?._id.length === 0) {
      return Promise.resolve();
    }

    const imageIndex = this.getImageIndexInImagesArray(image);

    if (imageIndex === -1) {
      return;
    }

    this.personalMediaGalleryService.downloadDocumentByUrl(image.fullUrl)
      .then(img => {
        return this.modalController.create({
          component: ImageEditorDialogComponent,
          componentProps: {
            imageFile: img
          },
          cssClass: 'image-web-dialog'
        });
      }).then(dlg => {
        dlg.onDidDismiss().then(res => {
          if (!res?.data) {
            return;
          }
          this.updatePersonalMediaGalleryDocument(image, imageIndex, res.data.file);
        });
        return dlg.present()
      }).catch(err => {
        this.showError(err?.message);
      });
  }

  addImages(): void {

    if (this.currentMaxImagesCount === 0) {

      return this.toast.show(
        'app.groups.CreateGroupCase.image-limit-reached-error-text',
        'app.groups.CreateGroupCase.image-limit-reached-error-title',
        ToastMode.ERROR);
    }

    this.fileSelectImage.selectFile();
  }

  addVideos(): void {

    if (this.currentMaxVideosCount === 0) {

      return this.toast.show(
        'app.groups.CreateGroupCase.video-limit-reached-error-text',
        'app.groups.CreateGroupCase.video-limit-reached-error-title',
        ToastMode.ERROR);
    }

    this.fileSelectVideo.selectFile();
  }


  updatePersonalMediaGalleryDocument(image: PersonalMediaGalleryDocumentWithStageOfTreatment, imageIndex: number, imageFile: File): void {

    const uploadFile: UploadFile = {
      key: 'image',
      file: imageFile,
      fileName: image.fileName,
      mimeType: imageFile.type
    }

    this.uploadService.updatePersonalMediaGalleryDocument(uploadFile, image._id, this.FileSelectScope.CASE)
      .then((res: UpdatePersonalMediaGalleryDocumentSuccessResponse) => {

        const stageOfTreatmentId = this.images[imageIndex].stageOfTreatmentId;
        this.images[imageIndex] = res.result;
        this.images[imageIndex].stageOfTreatmentId = stageOfTreatmentId;
      }).catch(err => {
        this.showError(err?.message);
      });
  }

  get disableImageDeleteButton(): boolean {
    return this.images?.length === 1;
  }

  uploadPersonalMediaGalleryDocuments(imageFiles: Array<SelectedFile>): void {
    this.caseImagesUploading += 1;
    const uploadData: Array<UploadFile> = [];

    imageFiles.forEach(image => {

      uploadData.push({
        key: 'image',
        file: image.file,
        fileName: this.getFileName(image),
        mimeType: image.file.type
      })
    });

    let isVideoUpload = false;
    this.uploadService.uploadPersonalMediaGalleryDocuments(uploadData, this.FileSelectScope.CASE)
      .then((response: UploadPersonalMediaGalleryDocumentsSuccessResponse) => {

        this.images = this.images.map(image => {
          if (response?.result.length && (!image?._id || image?._id?.length === 0)) {
            const galleryImage = response?.result[0];
            (galleryImage as any).stageOfTreatmentId = image.stageOfTreatmentId;
            response?.result.splice(0, 1);
            if (isVideo(galleryImage.mimeType)) {
              isVideoUpload = true;
              return image; // will be updated with socket notification
            }
            return galleryImage;
          } else {
            return image;
          }
        });

        this.emitImagesChanged();

        setTimeout(() => {
          this.initTutorialBubbleRelativeToElements();
        }, 300);

      }).catch(error => {
        this.showError(error?.message);
      }).finally(() => {
        this.caseImagesUploading -= 1;
        if (isVideoUpload) {
          this.caseImagesProcessing += 1;
        }
      });
  }

  imagesSelected(imageFiles: Array<SelectedFile>): void {
    this._addFiles(imageFiles);
    this.uploadPersonalMediaGalleryDocuments(imageFiles);
  }

  showImagesSelectionDialog(): void {

    if (isMobilePlatform(this.config.platform)) {
      if (this.fileSelectImage?.selectImageMobile) {
        this.fileSelectImage.selectImageFromGalleryMobile();
      }
    } else {
      if (this.fileSelectImage?.selectFileWeb) {
        this.fileSelectImage.selectFileWeb();
      }
    }
  }

  get currentMaxImagesCount(): number {
    const imagesCount = (this.images || []).filter(item => {
      return isImage(item.mimeType);
    }).length;
    const maxCount = this.maxImagesCount - imagesCount;
    return maxCount < 0 ? 0 : maxCount;
  }

  videosSelected(videoFiles: Array<SelectedFile>): void {
    this._addFiles(videoFiles);
    this.uploadPersonalMediaGalleryDocuments(videoFiles);
  }

  _addFiles(files: Array<SelectedFile>): void {
    if (!this.images) {
      this.images = [];
    }

    this.images = [...files.map(item => {
      return {
        _id: '',
        deleted: false,
        createdDate: '',
        previewUrl: '',
        blurredUrl: '',
        fullUrl: '',
        mimeType: item.file.type,
        fileName: this.getFileName(item),
        contentLength: item.file.size,
        stageOfTreatmentId: this.stageOfTreatment?.length ? this.stageOfTreatment : undefined
      }
    }), ...this.images];

    this.swiperLogic.afterViewInit();
  }

  get currentMaxVideosCount(): number {
    const videosCount = (this.images || []).filter(item => {
      return isVideo(item.mimeType);
    }).length;
    const maxCount = this.maxVideosCount - videosCount;
    return maxCount < 0 ? 0 : maxCount;
  }

  showVideosSelectionDialog(): void {

    if (isMobilePlatform(this.config.platform)) {
      if (this.fileSelectVideo?.selectImageMobile) {
        this.fileSelectVideo.selectAVideoMobile();
      }
    } else {
      if (this.fileSelectVideo?.selectFileWeb) {
        this.fileSelectVideo.selectFileWeb();
      }
    }
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  get disableWatermarkButton(): boolean {
    return !this.images?.length;
  }

  get shouldShowActionSheet(): boolean {
    return !this.isDesktop;
  }

  showPageSettings(): void {
    if (!this.createEditCasePostBottomBar?.showPageSettings ||
      !this.shouldShowActionSheet || this.clinicalCase) {
      return;
    }
    this.createEditCasePostBottomBar.showPageSettings();
  }

  closeSettings(): void {
    if (this.createEditCasePostBottomBar?.closeSettings) {
      this.createEditCasePostBottomBar.closeSettings();
    }
  }

  toggleWatermark(): void {

    if (!this.images?.length) {
      const noImagesErrorMessage = this.appTranslationService.instant('app.watermark.no-images-watermark-error');
      this.toast.showWithMessage(noImagesErrorMessage, null, ToastMode.ERROR);
      return;
    }

    this.watermarked = !this.watermarked;

    if (this.clinicalCase) {
      this.clinicalCase.watermarked = this.watermarked;
    }
  }

  isVideo(image: PersonalMediaGalleryDocumentWithStageOfTreatment): boolean {
    return isVideo(image.mimeType);
  }

  onPlay(image: PersonalMediaGalleryDocumentWithStageOfTreatment): void {
    this.playVideo.emit({
      url: image.fullUrl,
      mimeType: image.mimeType,
      thumbnailImageUrl: this.watermarked ? image?.watermarkedPreviewUrl : image?.previewUrl
    });
  }

  getFileName(selectedFile: SelectedFile): string {
    return appConfig.platform === Platform.IOS || appConfig.platform === Platform.ANDROID ?
      selectedFile?.fileName : selectedFile.file.name;
  }

  get showTutorialStep3Bubble(): boolean {
    const shouldShow = this.stageOfTreatmentElement?.clientHeight > 0 &&
      this.showTutorialStepXBubble(3) &&
      this.classificationLevelOne ? true : false;

    if (shouldShow) {
      this.tutorialBubbleStep3Showed.emit();
    }

    return shouldShow;
  }

  get showTutorialStep4Bubble(): boolean {

    let allImagesAreTagged = false;

    if (this.images && this.images?.length) {

      allImagesAreTagged = true;

      this.images.forEach(image => {
        if (!image.stageOfTreatmentId) {
          allImagesAreTagged = false;
        }
      })
    }

    const show = this.showTutorialStepXBubble(4) && allImagesAreTagged
      ? true : false;

    if (show) {

      if (this.activeSlide === -1) {
        return false;
      }

      // get current slide edit button and it's position
      const editButtonElement = this.el.nativeElement.querySelectorAll('.edit-button')?.[this.activeSlide];
      const editImageButtonLeftPosition = editButtonElement?.getBoundingClientRect().left;
      const editImageButtonWidth = editButtonElement?.clientWidth;
      this.step4ArrowLeftPosition = `${(editImageButtonLeftPosition - (editImageButtonWidth / 2)) + 6.5}px`;
    }

    return show;
  }

  onSwiper(swiper: Swiper): void {
    this.swiper = swiper;
  }

  get activeSlide(): number {
    return this.swiper ? this.swiper?.activeIndex : -1;
  }

  emitImagesChanged(): void {
    this.imagesChanged.emit(this.images);
  }

  trackUploadLimitationsFail(fileName: string, mimeType: string, scope: FileSelectScope, reason: string): Promise<void> {
    const paramsToTrack: UploadMediaTrackingParam = {
      objectId: fileName,
      objectType: mimeType,
      scope: scope,
      uploadFailed: true, // dont think we need this ... since we send this only if upload fails
      reason: reason
    };

    const trackData: TrackingRequest = {
      action: ActionTracked.uploaded,
      params: paramsToTrack
    };

    return this.trackingService.track(trackData).catch(_err => {
      console.error('Something went wrong on upload action: ' + _err);
    });
  }

  get filteredImages(): Array<PersonalMediaGalleryDocumentWithStageOfTreatment> {

    if (!this.images || !this.images?.length) {
      return [];
    }

    return this.images.filter(image => !image.hidden);
  }

  private getTrackingParamFileName(selectedFiles: Array<SelectedFile>): string {

    if (!selectedFiles || !selectedFiles?.length) {
      return '';
    }

    const fileNames: Array<string> = [];
    let fileName = '';

    selectedFiles.forEach((selectedFile: SelectedFile) => {
      fileNames.push(selectedFile.file?.name || selectedFile?.fileName);
    });

    fileName = fileNames.length > 1 ? fileNames.join(',') : fileNames[0];
    return fileName;
  }

  private getTrackingParamFileMimeType(selectedFiles: Array<SelectedFile>): string {

    if (!selectedFiles || !selectedFiles?.length) {
      return '';
    }

    const fileMimeTypes: Array<string> = [];
    let fileMimeType = '';

    selectedFiles.forEach((selectedFile: SelectedFile) => {
      fileMimeTypes.push(selectedFile?.file?.type);
    });

    fileMimeType = fileMimeTypes.length > 1 ? fileMimeTypes.join(',') : fileMimeTypes[0];
    return fileMimeType;
  }

  private showTutorialStepXBubble(step: number): boolean {
    if (
      !this.tutorialInfo ||
      this.tutorialInfo?.finished ||
      this.tutorialInfo?.skipped ||
      this.tutorialInfo.currentStep !== step) {
      return false;
    }

    return true;
  }

  private showError(msg: string): void {
    this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
  }

  private addMessageToBodyElement(messageContainerClass: string): void {
    const uploadingMessageEl = this.el.nativeElement.querySelector(messageContainerClass);

    if (uploadingMessageEl) {
      document.body.appendChild(uploadingMessageEl);
    }
  }

  private removeMessageFromBodyElement(messageContainerClass: string): void {
    const uploadingMessageElements = document.querySelectorAll(messageContainerClass);

    if (uploadingMessageElements?.length) {
      uploadingMessageElements.forEach(el => document.body.removeChild(el));
    }
  }

  private screenSizeChanged() {
    if (this.shouldShowActionSheet && appConfig.platform === Platform.BROWSER) {
      this.showPageSettings()
    } else {
      this.closeSettings();
    }
  }

  private initTutorialBubbleRelativeToElements(): void {
    this.stageOfTreatmentElement = this.el.nativeElement.querySelector('.card-content > .label-container');
    this.imageActionButtonsElement = this.el.nativeElement.querySelector('.action-buttons-container');
  }

  private getImageIndexInImagesArray(image: PersonalMediaGalleryDocumentWithStageOfTreatment): number {

    if (!this.images || !this.images.length) {
      return -1;
    }

    return this.images?.findIndex(img => img?._id === image._id);
  }

  async reorderMedia(): Promise<void> {

    if (!this.areAllImagesTagedWithStageOfTreatment) {
      this.showError(this.appTranslationService.
        instant('app.dialogs.ReorderCaseMediaDialog.reorder-not-tagged-images-error-message'));
      return;
    }

    const preOpGroup: GroupedImagesByStageOfTreatment = {
      title: this.appTranslationService.instant('app.dialogs.ReorderCaseMediaDialog.preOp'),
      id: StageOfTreatmentId.PRE_OP,
      items: this.images?.filter(image => image?.stageOfTreatmentId === StageOfTreatmentId.PRE_OP) || []
    };

    const intraOpGroup: GroupedImagesByStageOfTreatment = {
      title: this.appTranslationService.instant('app.dialogs.ReorderCaseMediaDialog.intraOp'),
      id: StageOfTreatmentId.INTRA_OP,
      items: this.images?.filter(image => image?.stageOfTreatmentId === StageOfTreatmentId.INTRA_OP) || []
    };

    const postOpGroup: GroupedImagesByStageOfTreatment = {
      title: this.appTranslationService.instant('app.dialogs.ReorderCaseMediaDialog.postOp'),
      id: StageOfTreatmentId.POST_OP,
      items: this.images?.filter(image => image?.stageOfTreatmentId === StageOfTreatmentId.POST_OP) || []
    };

    const clinicalFollowUpGroup: GroupedImagesByStageOfTreatment = {
      title: this.appTranslationService.instant('app.dialogs.ReorderCaseMediaDialog.followUp'),
      id: StageOfTreatmentId.FOLLOW_UP,
      items: this.images?.filter(image => image?.stageOfTreatmentId === StageOfTreatmentId.FOLLOW_UP) || []
    };

    try {
      const reorderedImages = await this.uiUtilsService.showCaseMediaReorderDialog([preOpGroup,
        intraOpGroup,
        postOpGroup,
        clinicalFollowUpGroup], 'editMedia', this.clinicalCase?.watermarked);

      if (!reorderedImages?.length) {
        return;
      }

      this.images = [];

      reorderedImages.forEach(group => {
        this.images = [...this.images, ...group.items || []];
      });

      this.emitImagesChanged();

    } catch (err) {
      console.error(err);
      if (err?.error?.error?.message?.errfor?.message) {
        this.showError(err?.error?.error?.message?.errfor?.message);
      }
    }
  }

}
