import { ModalController } from '@ionic/angular';
import { VideoDialogComponent } from './video-dialog/video-dialog.component';

export function openVideoDialog(url: string, mimeType: string, thumbnailImageUrl: string, modalController: ModalController): void {
  modalController.create({
    component: VideoDialogComponent,
    componentProps: {
      videoUrl: url,
      videoMimeType: mimeType,
      videoThumbnailImageUrl: thumbnailImageUrl
    },
    cssClass: 'image-web-dialog'
  }).then(dlg => {
    dlg.present();
  });
}
