import { NgModule } from '@angular/core';
import {AllowOverflowToolbarDirective} from './allow-overflow-toolbar.directive';
import { LoadIndicatorDirective } from './load-indicator.directive';
import { SwiperContentDirective } from './swiper-content.directive';
import { SwiperTemplateDirective } from './swiper-template.directive';
import { LongPressDirective } from './long-press.directive';
import { TooltipDirective } from './tooltip.directive';
import { MultiClickDirective } from './multi-click.directive';
import { ScrollingTrackerDirective } from './scrolling-tracker.directive';
import { IconComponent } from '../modules/icon/icon.component';
import { LimitInputDirective } from './limit-input.directive';

@NgModule({
  imports: [
    IconComponent
  ],
  exports: [
    AllowOverflowToolbarDirective,
    TooltipDirective,
    SwiperTemplateDirective,
    SwiperContentDirective,
    LoadIndicatorDirective,
    MultiClickDirective,
    LongPressDirective,
    ScrollingTrackerDirective,
    LimitInputDirective
  ],
  declarations: [
    AllowOverflowToolbarDirective,
    TooltipDirective,
    SwiperTemplateDirective,
    SwiperContentDirective,
    LoadIndicatorDirective,
    LongPressDirective,
    MultiClickDirective,
    ScrollingTrackerDirective,
    LimitInputDirective
  ]
})
export class MIDirectivesModule { }
