import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { ImageGalleryDialogComponent } from '../components/image-gallery-dialog/image-gallery-dialog.component';
import { ImageAttachment } from 'src/app/services/attachments.model';
import {
  ImageGallery,
  ActionContent,
  GalleryConfig,
  ReviewGalleryModeData,
  ImageGalleryActions
} from './image-gallery.model';


@Injectable({
  providedIn: 'root'
})
export class ImageGalleryService implements ImageGallery {

  private imageGalleryAction: Subject<ActionContent> = new Subject();
  private imagesChanged: Subject<Array<ImageAttachment>> = new Subject();
  private imageChanged: Subject<ImageAttachment> = new Subject();
  constructor(
    private modalController: ModalController
  ){}

  async openImageGallery(galleryConfig: GalleryConfig): Promise<ReviewGalleryModeData> {

    const galleryModal = await this.modalController.create(
      {
        component: ImageGalleryDialogComponent,
        componentProps: {
          imageGalleryService: this,
          ...galleryConfig
        },
        cssClass: 'image-web-dialog'
      });

    galleryModal.present();

    try {
      const res = await galleryModal.onDidDismiss();

      if (res && res?.data) {
        return res?.data;
      }

      return null;

    } catch (err) {
      console.log(err);
      return null;
    }
  }

  get imagesStream(): Observable<Array<ImageAttachment>> {
    return this.imagesChanged.asObservable();
  }

  get imageUpdated(): Observable<ImageAttachment> {
    return this.imageChanged.asObservable();
  }

  updateGalleryImages(images: Array<ImageAttachment>): void {
    this.imagesChanged.next(images);
  }

  get imageActionStream(): Observable<ActionContent> {
    return this.imageGalleryAction.asObservable();
  }

  triggerAction(action: ImageGalleryActions, index: number, _id: string, galleryId: string = null): void {

    this.imageGalleryAction.next({
        action: action,
        index: index,
        _id: _id,
        galleryId: galleryId
      });
  }

  removeImage(index: number, imageId: string, galleryId: string = null): void {
    this.triggerAction(ImageGalleryActions.REMOVE_IMAGE, index, imageId, galleryId);
  }

  triggerImageChanged(image: ImageAttachment): void {
    this.imageChanged.next(image);
  }
}
