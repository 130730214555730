import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  Output
} from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
})
export class ChipsComponent implements AfterViewChecked, AfterViewInit, OnDestroy {

  @Input() preventTextOverflow = true;
  @Input() preventRenderAnimation = false;

  @Output() renderedChipsCountChanged: EventEmitter<number> = new EventEmitter();

  fixingChipsMargins = true;
  private marginsFixed: boolean;
  private chips: Array<any>;
  private chipsCountObserver: any;

  private debounceResize = _.debounce(() => {
    this.fixChipsMargins();
  }, 300);

  constructor(
    private el: ElementRef,
    private zone: NgZone
  ) { }

  @HostListener('window:resize', ['$event'])
  windowResize(): void {
    this.debounceResize();
  }

  ngAfterViewInit(): void {
    this.setChipsCountObserver();
    this.initChipsArray();
  }

  ngAfterViewChecked(): void {
    if (!this.marginsFixed) {
      this.fixChipsMargins();
    }
  }

  ngOnDestroy(): void {
    this.chipsCountObserver?.disconnect();
  }

  initChipsArray(): void {
    this.chips = this.el.nativeElement.querySelector('.chips-container')?.children;
    setTimeout(() => {
      this.fixChipsMargins();
      this.renderedChipsCountChanged.emit(this.chips?.length || 0);
    }, 100);
  }

  setChipsCountObserver(): void {

    const targetNode = this.el.nativeElement.querySelector('.chips-container');
    const config = { childList: true };

    if (!this.chipsCountObserver) {
      this.chipsCountObserver = new (window as any).MutationObserver(mutationsList => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            this.initChipsArray();
          }
        }
      });
    }

    this.chipsCountObserver?.observe(targetNode, config);
  }

  fixChipsMargins(): void {

    if (this.chips && this.chips.length > 0 && this.chips[0].clientWidth > 0) {
      this.marginsFixed = true;
      this.fixingChipsMargins = true;

      for (const chip of this.chips) {
        chip.removeAttribute('style');
      }

      setTimeout(() => {

        if (!this.chips?.length) {
          return;
        }

        const firstChipOffsetTop: number = this.chips[0].offsetTop;
        const firstChipOffsetLeft: number = this.chips[0].offsetLeft;
        this._fixChipsMarings(firstChipOffsetTop, firstChipOffsetLeft);

        this.zone.run(() => {
          this.fixingChipsMargins = false;
        });
      }, 300);
    }
  }

  private _fixChipsMarings(firstChipOffsetTop: number, firstChipOffsetLeft: number) {

    let prevChip: any = null;
    const marginRightStyle = 'margin-right: 0; ';

    const marginBetweenChips = parseInt(getComputedStyle(this.el.nativeElement)
      .getPropertyValue('--chips-margin'), 10);

    for (const chip of this.chips) {

      let chipStyle = '';

      if (chip.offsetTop === firstChipOffsetTop) {
        chipStyle += 'margin-top: 0; ';
      }

      if (chip && prevChip) {

        const style = prevChip.getAttribute('style');

        if (chip.offsetLeft === firstChipOffsetLeft) {
          prevChip.setAttribute('style', `${style}${marginRightStyle}`);
        }
      }

      chip.setAttribute('style', chipStyle);
      prevChip = chip;
    }

    prevChip = null;

    for (const chip of this.chips) {

      if (chip && prevChip) {

        let style = prevChip.getAttribute('style');

        const distanceWithPrevChip = chip.offsetLeft - (prevChip.offsetLeft + prevChip.offsetWidth);

        if (distanceWithPrevChip > -1 && distanceWithPrevChip < marginBetweenChips) {
          style = prevChip.getAttribute('style');
          style = style.replace(marginRightStyle, '');
          prevChip.setAttribute('style', style);
        }
      }

      prevChip = chip;
    }
  }
}
